
import React from 'react'
import { Link } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/main.css'
import '../css/main.scss'

const TopTrendingSkills = () => (
    <section className="categories">
        <div className="container">
            <div className="row mb-5">
                <div className="col-12 mb-4 mb-md-3">
                    <h4>Top skills</h4>
                </div>
                <div className="col-6 col-md-3 mb-4 mb-md-0">
                    <ul className="categories__list">
                        <li><Link to='/android-developer/' >Android Developer</Link></li>
                        <li><Link to='/bookkeeper/' >Bookkeeper</Link></li>
                        <li><Link to='/content-writer/' >Content Writer</Link></li>
                        <li><Link to='/copywriter/' >Copywriter</Link></li>
                        <li><Link to='/customer-service-representative/' >Customer Service Representative</Link></li>
                        <li><Link to='/database-administrator/' >Database Administrator</Link></li>
                        <li><Link to='/data-scientist/' >Data Scientist</Link></li>
                        <li><Link to='/facebook-developer/' >Facebook Developer</Link></li>
                    </ul>
                </div>
                <div className="col-6 col-md-3 mb-4 mb-md-0">
                    <ul className="categories__list">
                        <li><Link to='/front-end-developer/' >Front-End Developer</Link></li>
                        <li><Link to='/game-developer/' >Game Developer</Link></li>
                        <li><Link to='/graphic-designer/' >Graphic Designer</Link></li>
                        <li><Link to='/information-security-analyst/' >Information Security Analyst</Link></li>
                        <li><Link to='/ios-developer/' >iOS Developer</Link></li>
                        <li><Link to='/java-developer/' >Java Developer</Link></li>
                        <li><Link to='/javascript-developer/' >JavaScript Developer</Link></li>
                        <li><Link to='/logo-designer/' >Logo Designer</Link></li>
                    </ul>
                </div>
                <div className="col-6 col-md-3">
                    <ul className="categories__list">
                        <li><Link to='/mobile-app-developer/' >Mobile App Developer</Link></li>
                        <li><Link to='/php-developer/' >PHP Developer</Link></li>
                        <li><Link to='/python-developer/' >Python Developer</Link></li>
                        <li><Link to='/resume-developer/' >Resume Developer</Link></li>
                        <li><Link to='/sales-consultant/' >Sales Consultant</Link></li>
                        <li><Link to='/seo/' >SEO Expert</Link></li>
                        <li><Link to='/social-media-manager/' >Social Media Manager</Link></li>
                        <li><Link to='/software-developer/' >Software Developer</Link></li>
                    </ul>
                </div>
                <div className="col-6 col-md-3">
                    <ul className="categories__list">
                        <li><Link to='/software-engineer/' >Software Engineer</Link></li>
                        <li><Link to='/technical-writer/' >Technical Writer</Link></li>
                        <li><Link to='/ui-designer/' >UI Designer</Link></li>
                        <li><Link to='/ux-designer/' >UX Designer</Link></li>
                        <li><Link to='/virtual-assistant/' >Virtual Assistant</Link></li>
                        <li><Link to='/web-designer/' >Web Designer</Link></li>
                        <li><Link to='/wordpress/' >Wordpress Developer</Link></li>
                        <li><Link to='/writer/' >Writer</Link></li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-4 mb-md-3">
                    <h4>Trending skills</h4>
                </div>
                <div className="col-6 col-md-3 mb-4 mb-md-0">
                    <ul className="categories__list">
                        <li><Link to='/apple-uikit/' >Apple UIKit</Link></li>
                        <li><Link to='/apple-xcode/' >Apple Xcode</Link></li>
                        <li><Link to='/articulate-storyline/' >Articulate storyline</Link></li>
                        <li><Link to='/atlassian-confluence/' >Atlassian Confluence</Link></li>
                        <li><Link to='/blockchain/' >Blockchain</Link></li>
                        <li><Link to='/amazon-aws/' >Amazon AWS</Link></li>
                        <li><Link to='/react/' >React</Link></li>
                        <li><Link to='/shopify/' >Shopify</Link></li>
                    </ul>
                </div>
                <div className="col-6 col-md-3 mb-4 mb-md-0">
                    <ul className="categories__list">
                        <li><Link to='/customer-retention/' >Customer retention</Link></li>
                        <li><Link to='/elearning/' >eLearning</Link></li>
                        <li><Link to='/enterprise-architecture/' >Enterprise architecture</Link></li>
                        <li><Link to='/gitlab/' >GitLab</Link></li>
                        <li><Link to='/go-development/' >Go development</Link></li>
                        <li><Link to='/branding/' >Branding</Link></li>
                        <li><Link to='/web-development/' >Web Development</Link></li>
                    </ul>
                </div>
                <div className="col-6 col-md-3 mb-4 mb-md-0">
                    <ul className="categories__list">
                        <li><Link to='/google-app-engine-api/' >Google App Engine API</Link></li>
                        <li><Link to='/google-cloud/' >Google Cloud Platform</Link></li>
                        <li><Link to='/nodejs/' >Node.js</Link></li>
                        <li><Link to='/product-photography/' >Product photography</Link></li>
                        <li><Link to='/rapid-prototyping/' >Rapid prototyping</Link></li>
                        <li><Link to='/gatsbyjs/' >Gatsby</Link></li>
                        <li><Link to='/react-native/' >React Native</Link></li>
                    </ul>
                </div>
                <div className="col-6 col-md-3 mb-4 mb-md-0">
                    <ul className="categories__list">
                        <li><Link to='/risk-management/' >Risk management</Link></li>
                        <li><Link to='/scala-development/' >Scala development</Link></li>
                        <li><Link to='/scorm/' >SCORM</Link></li>
                        <li><Link to='/tensorflow/' >Tensorflow</Link></li>
                        <li><Link to='/volusion/' >Volusion</Link></li>
                        <li><Link to='/expo-developer/' >Expo</Link></li>
                        <li><Link to='/typescript/' >Typescript</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
)

export default TopTrendingSkills