
import React from 'react'
import { Link } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/main.css'
import '../css/main.scss'

const HireAndApply = () => (
    <section className="cta">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <h2 className="h2--lg cta__title">Get pre-screened talent,<br />hand picked for your company</h2>
                <div className="cta__btns">
                    <Link to='/hire/' className="btn">HIRE TOP TALENT</Link>
                    <Link to='/apply/' className="btn btn--outline-white">APPLY AS A FREELANCER</Link>
                </div>
            </div>
        </div>
    </div>
</section>
)

export default HireAndApply